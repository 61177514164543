import { get, post, put, destroy, patch } from "./_utils";

// gets all the forms
export const getForms = () => {
  return get("/forms.json");
};

// gets the details for a new form
export const getNewForm = () => {
  return get("/forms/new.json");
};

// get all the details regarding the given form
export const getFormDetails = (id) => {
  return get(`/forms/${id}/edit.json`);
};

// get filtered form data: name, startDate, endDate, maxSubmissions
export const getLimitedFormDetails = (id) => {
  return get(`/forms/${id}.json`);
};

// create a form with given params
export const createForm = (data, params = {}) => {
  return post(`/forms.json`, data, params);
};

// update an existing form
export const updateForm = (id, data) => {
  return put(`/forms/${id}.json`, data);
};

// destroy a given form
export const deleteForm = (id) => {
  return destroy(`/forms/${id}.json`);
};

// clone a given form
export const cloneForm = (id) => {
  return post(`/forms/${id}/clone.json`);
};

// toggle form status
export const toggleFormStatus = (id) => {
  return patch(`/forms/${id}/publish_or_unpublish.json`);
};

// partial update form
export const partialUpdateForm = (id, data) => {
  return patch(`/forms/${id}.json`, data);
};

// sends a submission with form id and current user email
// requesting our support in creating form
export const getSupport = (url, data) => {
  return post(url, data);
};

// get survey from details regarding the given form
export const getFormSurveyDetails = (id, params = {}) => {
  return get(`/f/${id}.json`, params);
};

// update last viewed submission time
export const updateLastViewedSubmission = (id) => {
  return get(`/forms/${id}/update_submission_last_viewed_at.json`);
};

// add/update form rules
export const saveFormRules = (id, rules) => {
  return put(`/forms/${id}/update_rules`, { rules: rules });
};

// Aggregating submission data for form
export const getAggregatedSubmissionData = (formId) => {
  return get(`/forms/${formId}/aggregate_submission_data.json`);
};
